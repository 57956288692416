import { useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import TagManager from 'react-gtm-module'
import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { createTheme } from '@mui/system'
import { Grid, useMediaQuery } from '@mui/material'
import ReactPlayerLoader from '@brightcove/react-player-loader'

import DialogSubscribe from '../Common/DialogSubscribe/DialogSubscribe'
import './ReactPlayer.scss'
import {
  getDataFromUrl,
  objectToQueryString,
  isFullScreen,
  exitFullScreen
} from '../../utils/utils'
import { getStorage } from '../../utils/localStorageSupport'
import { ContinueWatchingObject } from '../../types/userProfile'
import { PlayerSection, Section, VideoDetailSection } from '../../types/section'
import { ContentSeasonData } from '../../types/season'
import { continueWatchingFailure } from '../../store/slices/continueWatchingSlice'
import { userSelector } from '../../store/slices/config/configSelectors'
import { useLazyGetUserProfileQuery } from '../../store/services/user/userProfileApi'
import { useUpdateContinueWatchingMutation } from '../../store/services/user/continueWatchingApi'
import { useGetVideoDetailPageQuery } from '../../store/services/page/pageApi'
import { useLazyCheckEntitlementApiQuery } from '../../store/services/entitlement/entitlementApi'
import { useLazyGetDynamicStringsQuery } from '../../store/services/dynamicStrings/dynamicStringsApi'
import { useLazyGetContentSeasonDetailsQuery } from '../../store/services/content/contentApi'
import { RootState } from '../../store'
import {
  googlePublishBannerAdsForPlayer,
  googlePublishCompanionAds
} from '../../hooks/useGooglePublisherTag'
import { entitlementPayload } from '../../constants'
import config from '../../config'
import { SVGImage } from '../../assets/images'

interface ReactPlayerProps {
  section: PlayerSection
}

const ReactPlayer: FC<ReactPlayerProps> = () => {
  const user = useSelector(userSelector)
  const { data: userData } = useSelector((state: RootState) => state.userProfile)
  const { successEntitlement } = useSelector((state: RootState) => state.entitlement)
  const [isPlaying, setIsPlaying] = useState(false)
  const [triggerEvent, setTriggerEvent] = useState('ad')
  const dispatch = useDispatch()
  const [updateContinueWatching]: any = useUpdateContinueWatchingMutation()
  const playerRef = useRef(null) as MutableRefObject<ReactPlayerLoader>
  const [getProfileData] = useLazyGetUserProfileQuery()
  const [isFirstLoad, setFirstLoad] = useState(true)
  const [getAdSlotId]: any = useLazyGetDynamicStringsQuery()
  const [detailApi] = useLazyGetContentSeasonDetailsQuery()
  const { relatedContent } = useSelector((state: RootState) => state.player)
  const getQuery = new URLSearchParams(window.location?.search)
  let trailerId = getQuery.get('trailer')
  const objParam = getDataFromUrl(getQuery.get('data')!)
  const displayName = objParam['displayName']
  const seriesName = objParam['seriesName']
  const { id } = useParams()
  const { data } = useGetVideoDetailPageQuery({ id })
  const [contentId, setContentId] = useState(id)
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  const videoToPlay = useRef(contentId) as MutableRefObject<string | undefined>
  const [trackNextButtonChange, setNextButtonChange] = useState(false)
  const [trackPrevButtonChange, setPrevButtonChange] = useState(false)
  const [selIndex, setIndex] = useState(-1)
  const [nextPrevCollection, setNextPrevCollection] = useState<ContentSeasonData[]>([])
  const [playlist, setPlaylist] = useState(relatedContent)
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const [isSubscribeDialogOpen, setIsSubscribeDialogOpen] = useState(false)
  const [originalPlaybackRate, setOriginalPlaybackRate] = useState(1)
  const lastProgressPercentReported = useRef(0)
  const storage = getStorage()
  const uid = storage?.getItem('uid')
  const [checkEntitlementApi] = useLazyCheckEntitlementApiQuery()
  const [isAdsDisable, setIsAdsDisable] = useState(false)
  const [isBeforeStartTime, setIsBeforeStartTime] = useState(false)
  const [posterUrlObj, setposterUrlObj] = useState(Object)

  useEffect(() => {
    setContentId(id)
  }, [id])

  useEffect(() => {
    if (data?.sections?.length) {
      const isVideoDetailSection = (section: Section): section is VideoDetailSection =>
        section.type === 'VideoDetail'
      const videoDetailItem = data.sections.find(isVideoDetailSection)
      if (videoDetailItem?.content) {
        const { content } = videoDetailItem
        setposterUrlObj(content.images)

        if (content.start_date) {
          const startTime = new Date(content.start_date)
          setIsBeforeStartTime(new Date() < startTime)
        }
      }
    }
    return () => {
      setIsBeforeStartTime(false)
    }
  }, [data])

  const updateContinueWatchingList = async (progressPercent) => {
    if (!user?.uid || user?.uid?.length === 0) {
      return
    } else {
      let progress = 0
      if (progressPercent >= 0 && progressPercent <= 24) {
        progress = 0
      } else if (progressPercent >= 25 && progressPercent <= 49) {
        progress = 25
      } else if (progressPercent >= 50 && progressPercent <= 74) {
        progress = 50
      } else if (progressPercent >= 75 && progressPercent <= 100) {
        progress = 75
      }
      if (contentId === videoToPlay.current) {
        const content: ContinueWatchingObject = {
          user_id: user?.uid,
          content_id: contentId,
          progress: progress
        }
        try {
          if (lastProgressPercentReported.current != progress) {
            lastProgressPercentReported.current = progress
            await updateContinueWatching(content)
          }
        } catch (err) {
          dispatch(continueWatchingFailure('' + err))
        }
      }
    }
  }

  const checkUserProgress = async () => {
    const profileResponse = await getProfileData()
    if (profileResponse && profileResponse.data && profileResponse.data.watching) {
      if (profileResponse.data != undefined && profileResponse.data['watching'].length > 0) {
        const profileVideo = profileResponse.data['watching'].filter(
          (obj) => obj.video_id === contentId
        )
        if (
          playerRef &&
          playerRef.current &&
          !isNaN(playerRef.current.duration()) &&
          isFirstLoad &&
          profileVideo[0]?.progress
        ) {
          playerRef?.current?.currentTime(
            (profileVideo[0]?.progress / 100) * playerRef?.current?.duration()
          )
          setFirstLoad(false)
        }
      }
    }
  }

  const setCatalogParams = (video_id) => {
    const catalogParams = {}
    // catalogParams['type'] = 'playlist'
    catalogParams['id'] = video_id && video_id !== '' ? video_id : contentId
    // catalogParams['policyKey'] =
    //   'BCpkADawqM1645iAVmKYAUV4bbnX4wi_oLown6snOxtrI7DfOMI-UTgc5oGSG5OCjnHwH9xo19PeW4Ky6mYtNeEciVfQSEaDkjJejd-vHEppGFYfQdqPLcGdRaSRbNv8UcgAqYpEY4Wqteiy'
    return catalogParams
  }

  const handleCatalogError = (error) => {
    let specificError
    playerRef?.current?.errors({
      errors: {
        'future-video-error': {
          headline: t('errorPrompt.future_video'),
          dismiss: false
        },
        VIDEO_CLOUD_ERR_VIDEO_NOT_PLAYABLE: {
          headline: t('errorPrompt.future_video'),
          dismiss: false
        }
      }
    })
    if (typeof playerRef?.current.catalog.error !== undefined) {
      specificError = playerRef?.current.catalog.error.data[0]
      if (
        specificError !== undefined &&
        (specificError.error_code == 'VIDEO_NOT_PLAYABLE' ||
          specificError.error_code == 'VIDEO_CLOUD_ERR_VIDEO_NOT_PLAYABLE')
      ) {
        setPlaylist([])
        setIsBeforeStartTime(true)
        playerRef?.current.error({ code: 'future-video-error' })
      } else {
        setPlaylist([])
        throw error
      }
    }
  }
  useEffect(() => {
    if (playerRef?.current?.catalog?.error) {
      i18n.on('languageChanged', () => {
        handleCatalogError(playerRef?.current?.catalog?.error)
      })
    }
  }, [i18n, playerRef?.current?.catalog])

  const addPlayerControls = async () => {
    const controlBar: any = document.querySelector('#content-detailed-player .vjs-control-bar')
    const newElement1 = document.createElement('div')
    const nextButton = document.createElement('img')
    nextButton.setAttribute('src', `${SVGImage.next_item}`)
    nextButton.id = 'vjs-next'
    newElement1.className = 'vjs-control vjs-next'
    newElement1.appendChild(nextButton)
    const insertBeforeNode1 = document.getElementsByClassName('vjs-volume-panel')[0]
    controlBar.insertBefore(newElement1, insertBeforeNode1)
    newElement1.style.display = 'none'

    const newElement2 = document.createElement('div')
    const prevButton = document.createElement('img')
    prevButton.setAttribute('src', `${SVGImage.prev_item}`)
    prevButton.id = 'vjs-prev'
    newElement2.className = 'vjs-control vjs-prev'
    newElement2.appendChild(prevButton)
    const insertBeforeNode2 = document.getElementsByClassName('vjs-next')[0]
    controlBar.insertBefore(newElement2, insertBeforeNode2)
    newElement2.style.display = 'none'

    const nextPlayListItem: HTMLElement = document.getElementsByClassName(
      'vjs-next-button'
    )[0] as HTMLElement
    if (nextPlayListItem) {
      nextPlayListItem.style.display = 'none'
    }
  }

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'page_view',
        page_name: 'vod_player',
        page_location: location?.href,
        content_id: contentId,
        content_title: displayName,
        user_id: user?.uid
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }, [])

  const nextButtonClickAction = (nextPrevCollectionList, index) => {
    const nextItem = nextPrevCollectionList.length > 0 ? nextPrevCollectionList[index + 1] : null
    if (nextItem) {
      const videoDetails = {
        seriesName: '',
        next_up_id: playerRef?.current?.catalog?.data?.custom_fields?.next_up,
        seriesDescription: '',
        displayName: nextItem['display_name']
      }
      setPlaylist([])
      playerRef.current = null
      navigate(`/video/${nextItem['video_id']}?data=${btoa(objectToQueryString(videoDetails))}`)
    }
  }

  useEffect(() => {
    const nextBtn = document.getElementsByClassName('vjs-next')[0] as HTMLElement
    const prevBtn = document.getElementsByClassName('vjs-prev')[0] as HTMLElement
    if (nextBtn) {
      nextBtn.onclick = () => {
        if (
          selIndex === (nextPrevCollection.length || 0) - 1 &&
          playerRef?.current?.catalog?.data?.custom_fields?.next_up
        )
          (async () => {
            await moveToNextSeasonOnNextClick().then((nextPrevCollectionList) => {
              nextButtonClickAction(nextPrevCollectionList, -1)
            })
          })()
        else {
          nextButtonClickAction(nextPrevCollection, selIndex)
        }
      }
    }
    if (prevBtn) {
      prevBtn.onclick = () => {
        const nextItem = nextPrevCollection.length > 0 ? nextPrevCollection[selIndex - 1] : null
        if (nextItem) {
          const videoDetails = {
            seriesName: '',
            next_up_id: '',
            seriesDescription: '',
            displayName: nextItem['display_name']
          }
          setPlaylist([])
          playerRef.current = null
          navigate(`/video/${nextItem['video_id']}?data=${btoa(objectToQueryString(videoDetails))}`)
        }
      }
    }
  }, [selIndex, nextPrevCollection])

  useEffect(() => {
    const nextBtn = document.getElementsByClassName('vjs-next')[0] as HTMLElement
    const prevBtn = document.getElementsByClassName('vjs-prev')[0] as HTMLElement
    if (nextBtn && trackNextButtonChange) {
      nextBtn.style.display = 'block'
    } else if (nextBtn && !trackNextButtonChange) {
      nextBtn.style.display = 'none'
    }
    if (prevBtn && trackPrevButtonChange) {
      prevBtn.style.display = 'block'
    } else if (prevBtn && !trackPrevButtonChange) {
      prevBtn.style.display = 'none'
    }
  }, [trackNextButtonChange, trackPrevButtonChange])

  const moveToNextSeasonOnNextClick = async () => {
    const items = await getPlaylistCollectionItems(
      playerRef?.current?.catalog?.data?.custom_fields?.next_up
    )
    setNextPrevCollection(items?.data || [])
    setIndex(-1)
    setNextButtonChange(false)
    setPrevButtonChange(false)
    return items?.data || []
  }

  const populateOverlayArray = () => {
    const overlayArray: any = []
    for (let i = 10; i > 0; i--) {
      const overlayObj = {
        content: `${i}    ${t('content-detail.next_episode')}`,
        start: playerRef?.current?.duration() - i,
        end: playerRef?.current?.duration() - (i - 1),
        align: 'bottom-right'
      }
      overlayArray.push(overlayObj)
    }
    return overlayArray
  }

  const setCountdownOverlay = () => {
    const overlayArray = populateOverlayArray()
    playerRef?.current?.overlay({
      class: 'customTimerOverlay',
      overlays: overlayArray
    })
  }

  useEffect(() => {
    if (
      selIndex === (nextPrevCollection.length || 0) - 1 &&
      playerRef?.current?.catalog?.data?.custom_fields?.next_up
    ) {
      if (triggerEvent !== 'ad') {
        if (triggerEvent !== 'ended' && triggerEvent !== '100') {
          setCountdownOverlay()
        }
        if (triggerEvent === 'ended' || triggerEvent === '100') {
          const nextBtn = document.getElementsByClassName('vjs-next')[0] as HTMLElement
          nextBtn.click()
        }
        const videoJsElement = document.querySelector('.video-js')
        const customTimerOverlayElements = videoJsElement
          ? videoJsElement.querySelectorAll('.customTimerOverlay')
          : null
        const handleClick = () => {
          const nextBtn = document.getElementsByClassName('vjs-next')[0] as HTMLElement
          nextBtn.click()
        }
        customTimerOverlayElements?.forEach((customTimerOverlayElement) => {
          customTimerOverlayElement?.addEventListener('click', handleClick)
        })
      }
    }
  }, [trackNextButtonChange, triggerEvent])

  const getPlaylistCollectionItems = async (collId) => {
    const items = await detailApi({
      id: collId
    })
    return items
  }

  const showOrHideNextPrevButtons = async () => {
    setNextButtonChange(false)
    setPrevButtonChange(false)
    if (playerRef?.current?.catalog?.data?.custom_fields?.lastep_collection_id) {
      const items = await getPlaylistCollectionItems(
        playerRef?.current?.catalog?.data?.custom_fields?.lastep_collection_id
      )
      setNextPrevCollection(items?.data || [])
      const index = items?.data?.findIndex(
        (object) => object['video_id'] === playerRef?.current?.catalog?.data?.id
      )
      setIndex(index!)
      if (index! > -1 && index! < (items?.data?.length || 0) - 1) {
        setNextButtonChange(true)
      } else {
        setNextButtonChange(false)
      }
      if (index && index > 0) {
        setPrevButtonChange(true)
      } else {
        setPrevButtonChange(false)
      }
    } else {
      setNextButtonChange(false)
      setPrevButtonChange(false)
    }
  }

  // eslint-disable-next-line
  const timeupdateHandler = (_evt: any) => {
    const checkPercent: number[] = [0, 25, 50, 75, 100]
    if (
      checkPercent.includes(
        Math.floor((playerRef.current.currentTime() / playerRef.current.duration()) * 100)
      )
    ) {
      setTriggerEvent(
        String(Math.floor((playerRef.current.currentTime() / playerRef.current.duration()) * 100))
      )
    }
    updateContinueWatchingList(
      Math.floor((playerRef.current.currentTime() / playerRef.current.duration()) * 100)
    )
  }

  const handleVideo = (video) => {
    playerRef.current.catalog.load(video)
    setOriginalPlaybackRate(playerRef.current.playbackRate())
    playerRef.current.on('loadedmetadata', () => {
      playerRef?.current?.muted(true)
      playerRef?.current?.bcAnalytics.client.setUser(user?.uid)
      isSafari && playerRef?.current?.playsInline(true)
      playerRef?.current?.play()
      const videoTitle = document.getElementsByClassName('vjs-dock-title')[0] as HTMLElement
      if (videoTitle) {
        videoTitle.innerHTML = seriesName || displayName || ''
      }
      // if (displayName !== undefined) videoTitle.innerHTML = displayName
      // else videoTitle.innerHTML = seriesName || ''
      showOrHideNextPrevButtons()

      // Select all the quality menu items
      const qualityMenuHtmlItems = document.querySelectorAll(
        '#content-detailed-player .vjs-quality-menu-wrapper .vjs-menu-content .vjs-menu-item'
      )
      const allMenuItem = playerRef.current.controlBar.getChild('QualityMenuButton').items

      const indexesWithHDSubLabel: number[] = []
      let autoIndex
      const maxResolutionLabel = successEntitlement?.features?.maxResolutionSupported || 720
      const preventResolution: number[] | any = []

      allMenuItem.forEach((item, i) => {
        const resolutionLabelWithoutP = item.options_.label.replace('p', '')
        if (parseInt(resolutionLabelWithoutP) > maxResolutionLabel) {
          indexesWithHDSubLabel.push(item.options_.levels[0])
          preventResolution.push(item.options_.label)
        }

        if (item.options_.controlText === 'Auto') autoIndex = i
      })

      playerRef.current.controlBar.getChild('QualityMenuButton').items[autoIndex].levels_ =
        allMenuItem[autoIndex].levels_.filter((item) => !indexesWithHDSubLabel.includes(item))

      playerRef.current.controlBar.getChild('QualityMenuButton').items[autoIndex].options_.levels =
        allMenuItem[autoIndex].options_.levels.filter(
          (item) => !indexesWithHDSubLabel.includes(item)
        )

      // Iterate over each quality menu item
      qualityMenuHtmlItems.forEach((item) => {
        if (preventResolution?.includes(item?.querySelector('.vjs-menu-item-text')?.textContent)) {
          item.addEventListener('click', () => {
            // if (!maxResolution) {
            if (isFullScreen()) {
              exitFullScreen()
            }
            let isSelected
            allMenuItem.forEach((item, i) => {
              if (item.isSelected_) isSelected = i
            })
            playerRef.current.controlBar
              .getChild('QualityMenuButton')
              .items[isSelected].handleClick_()
            setIsSubscribeDialogOpen(true)
            // }
          })
        }
        if (item?.textContent?.includes('Auto')) {
          item.addEventListener('click', () => {
            // if (!maxResolution) {
            playerRef.current.controlBar.getChild('QualityMenuButton').items[autoIndex].levels_ =
              allMenuItem[autoIndex].levels_.filter((item) => !indexesWithHDSubLabel.includes(item))

            playerRef.current.controlBar.getChild('QualityMenuButton').items[
              autoIndex
            ].options_.levels = allMenuItem[autoIndex].options_.levels.filter(
              (item) => !indexesWithHDSubLabel.includes(item)
            )
            // }
          })
        }
      })
    })

    playerRef.current.on('play', () => {
      if (videoToPlay.current == '') videoToPlay.current = contentId
      const videoTitle = document.getElementsByClassName('vjs-dock-title')[0] as HTMLElement
      // if (displayName !== undefined) videoTitle.innerHTML = displayName
      // else videoTitle.innerHTML = seriesName || ''
      if (videoTitle) {
        videoTitle.innerHTML = seriesName || displayName || ''
      }
      setIsPlaying(true)
      setTriggerEvent('play')
      if (playerRef.current?.paused()) {
        updateContinueWatchingList(
          Math.floor((playerRef?.current?.currentTime() / playerRef?.current?.duration()) * 100) ||
            0
        )
      }
    })

    playerRef.current.on('pause', () => {
      const videoTitle = document.getElementsByClassName('vjs-dock-title')[0] as HTMLElement
      // if (displayName !== undefined) videoTitle.innerHTML = displayName
      // else videoTitle.innerHTML = seriesName || ''
      if (videoTitle) {
        videoTitle.innerHTML = seriesName || displayName || ''
      }
      setIsPlaying(false)
      setTriggerEvent('pause')
      updateContinueWatchingList(
        Math.floor((playerRef?.current?.currentTime() / playerRef?.current?.duration()) * 100) || 0
      )
    })
    playerRef.current.on('adstart', () => {
      const nextBtn = document.getElementsByClassName('vjs-next')[0] as HTMLElement
      const prevBtn = document.getElementsByClassName('vjs-prev')[0] as HTMLElement
      const captionBtn = document.getElementsByClassName('vjs-subs-caps-button')[0] as HTMLElement
      if (nextBtn) {
        nextBtn.style.display = 'none'
      }
      if (prevBtn) {
        prevBtn.style.display = 'none'
      }
      if (captionBtn) {
        captionBtn.style.display = 'none'
      }
    })
    playerRef.current.on('ads-ad-started', () => {
      setIsPlaying(true)
      setTriggerEvent('ad')
    })
    playerRef.current.on('ads-ad-ended', () => {
      setTriggerEvent('ad')
    })
    playerRef.current.on('adend', () => {
      const videoTitle = document.getElementsByClassName('vjs-dock-title')[0] as HTMLElement
      // if (displayName !== undefined) videoTitle.innerHTML = displayName
      // else videoTitle.innerHTML = seriesName || ''
      if (videoTitle) {
        videoTitle.innerHTML = seriesName || displayName || ''
      }
      const nextBtn = document.getElementsByClassName('vjs-next')[0] as HTMLElement
      const prevBtn = document.getElementsByClassName('vjs-prev')[0] as HTMLElement
      const captionBtn = document.getElementsByClassName('vjs-subs-caps-button')[0] as HTMLElement
      if (nextBtn && trackNextButtonChange) {
        nextBtn.style.display = 'block'
      }
      if (prevBtn && trackPrevButtonChange) {
        prevBtn.style.display = 'block'
      }
      if (captionBtn) {
        captionBtn.style.display = 'block'
      }
      setIsPlaying(false)
      showOrHideNextPrevButtons()
      setTriggerEvent('')
    })
    playerRef.current.on('timeupdate', timeupdateHandler)

    playerRef.current.on('ended', () => {
      trailerId = ''
      setTriggerEvent('ended')
      updateContinueWatchingList(0)
      videoToPlay.current = ''
      // ser_id == '' get videid frm url
    })
    playerRef.current.on('beforeplaylistitem', (_, item) => {
      if (item.video_id !== videoToPlay.current) {
        const videoDetails = {
          seriesName: '',
          next_up_id: '',
          seriesDescription: '',
          displayName: item.name
        }
        setPlaylist([])
        playerRef.current = null
        navigate(`/video/${item.video_id}?data=${btoa(objectToQueryString(videoDetails))}`)
      }
    })
    //>>====SUBSCRIBE POPUP IF USER IS NOT SUBSCRIBED--------->
    playerRef.current.on('enterpictureinpicture', (e) => {
      if (!successEntitlement?.features?.isPIPEnabled) {
        if (isFullScreen()) {
          exitFullScreen()
        }
        e.preventDefault()
        document.exitPictureInPicture()
        setIsSubscribeDialogOpen(true)
      }
    })
    playerRef.current.on('ratechange', (e) => {
      if (!successEntitlement?.features?.isPlaybackSpeed) {
        if (isFullScreen()) {
          exitFullScreen()
        }
        e.preventDefault()
        const currentPlaybackRate = playerRef.current.playbackRate()
        // Check if the playback rate has changed
        if (currentPlaybackRate !== originalPlaybackRate) {
          // Adjust the playback rate back to the original value
          playerRef.current.playbackRate(originalPlaybackRate)
        }
        setIsSubscribeDialogOpen(true)
      }
    })
    const controlBar = playerRef.current.controlBar

    // Get the Chromecast button component
    const chromeCastButton = controlBar.getChild('ChromecastButton')
    // event listener to the Chromecast button
    chromeCastButton?.on('click', (event) => {
      if (!successEntitlement?.features?.isCastingEnabled) {
        if (isFullScreen()) {
          exitFullScreen()
        }
        event.preventDefault()
        event.stopImmediatePropagation()
        setIsSubscribeDialogOpen(true)
      }
    })
  }

  useEffect(() => {
    if (playerRef && playerRef.current && playlist?.length > 0) {
      playerRef.current.playlist(playlist, -1)
    } else if (playerRef && playerRef.current && playlist?.length === 0) {
      playerRef.current.playlist(playlist)
    }
  }, [playlist, playerRef?.current])

  const reloadPlayer = (video_id) => {
    if (playerRef && playerRef.current) {
      playerRef?.current?.pause()
      playerRef?.current?.src(null)
      playerRef?.current?.catalog.getVideo(setCatalogParams(video_id), (error, video) => {
        if (error) {
          handleCatalogError(error)
        } else if (!error) {
          if (video) {
            handleVideo(video)
            adSlotId()
          }
        }
      })
    }
  }

  const getAdSlotIdFromMW = async () => {
    const response = await getAdSlotId({
      q: 'web_companion_ad_slot_id'
    })
    return response
  }

  useEffect(() => {
    videoToPlay.current = trailerId ? trailerId : contentId
    if (contentId && contentId !== undefined) {
      playerRef.current = null
      reloadPlayer(videoToPlay.current)
    }
  }, [contentId, trailerId])

  const adSlotId = async () => {
    const response = await getAdSlotIdFromMW()
    const adId =
      // contentId && contentId !== undefined
      //   ? document.getElementById(`companionDiv`)
      document.getElementById(`companionDiv`)
    if (adId != null) {
      if (response && response.data && response.data.results && response.data.results.items) {
        if (response.data.results.items.length > 0) {
          googlePublishCompanionAds(response.data.results.items[0].value, adId.id)
        }
      }
    }
  }

  const handleSubscribeDialogClose = () => {
    setIsSubscribeDialogOpen(false)
  }

  const handleSubscribeDialogToSubscribe = () => {
    setIsSubscribeDialogOpen(false)
    navigate('/oned/premium')
  }

  useEffect(() => {
    adSlotId()
  }, [])

  const bannerAdSlotId = async () => {
    const response = await getAdSlotIdFromMW()
    const adIds = document.getElementsByClassName(`bannerDiv`)
    if (adIds != null && adIds.length > 0) {
      const ids: string[] = []
      for (let i = 0; i < adIds.length; i++) {
        const adDiv = adIds[i]
        ids.push(adDiv.id)
      }

      if (response && response.data && response.data.results && response.data.results.items) {
        if (response.data.results.items.length > 0) {
          googlePublishBannerAdsForPlayer(response.data.results.items[0].value, ids)
        }
      }
    }
  }

  useEffect(() => {
    if (triggerEvent !== 'ad') bannerAdSlotId()
  }, [isPlaying])

  useEffect(() => {
    if (triggerEvent !== 'ad') checkUserProgress()
  }, [isPlaying, triggerEvent])

  // useEffect(() => {
  //   const video = playerRef.current
  //   if (video && triggerEvent !== 'ad' && triggerEvent !== 'play') {
  //     let progressPercent = Math.floor((video.currentTime() / video.duration()) * 100)
  //     if (video.currentTime() === video.duration()) {
  //       progressPercent = 0
  //     }
  //     updateContinueWatchingList(progressPercent)
  //   }
  // }, [triggerEvent])

  useEffect(() => {
    const playlistArray: any = []
    relatedContent.forEach(function (item) {
      const arrItem = {}
      arrItem['poster'] = item?.images?.['poster']?.src
      arrItem['thumbnail'] = item?.images?.['thumbnail']?.src
      arrItem['name'] = item?.display_name
      arrItem['video_id'] = item?.video_id
      playlistArray.push(arrItem)
    })
    setPlaylist(playlistArray)
  }, [relatedContent])

  // TODO: if any data were to be sent to the API add a condition to not send it if pvtk is NOT null
  // const pvtk = useSelector(selectPreviewToken);

  // Entitlement api request payload

  const payload = entitlementPayload(uid as string, id as string)

  const adsEl = document.querySelector('.vjs-ima3-ad-container')
  useEffect(() => {
    if (uid) {
      checkEntitlementApi(payload).then((entitlement: any) => {
        if (entitlement.data.features?.isAds === false) {
          setIsAdsDisable(true)
          adsEl?.remove() // after refresh
        } else {
          setIsAdsDisable(false)
        }
      })
    } else {
      setIsAdsDisable(false)
    }
  }, [uid, adsEl])

  const onSuccessPlayer = (success) => {
    const mPlayer = success.ref

    // disabling the ads if the feature not true
    mPlayer.on('ima3-ads-manager-loaded', () => {
      if (isAdsDisable) {
        mPlayer.ima3.adsManager.destroy()
      }
    })

    mPlayer.ready(function () {
      playerRef.current = mPlayer
      mPlayer
        .getChild('controlBar')
        .el()
        .insertBefore(
          mPlayer.addChild('pictureInPictureToggle', {}).el(),
          document.querySelector('#content-detailed-player .vjs-fullscreen-control')
        )
      addPlayerControls()
      mPlayer.catalog.getVideo(setCatalogParams(videoToPlay.current), (error, video) => {
        if (error) {
          handleCatalogError(error)
        } else if (!error) {
          if (video) {
            handleVideo(video)
          }
        }
      })
    })
  }
  const theme = createTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // const [inactive, setInactive] = useState<string>(
  //   userData?.subscription?.subscription_status?.toLowerCase() as string
  // )
  // useEffect(() => {
  //   setInactive(userData?.subscription?.subscription_status?.toLowerCase() as string)
  // }, [userData?.subscription])

  return (
    <>
      <Grid
        width={`${isMobile ? '100%' : '60%'}`}
        margin='auto'
        key={trailerId ? trailerId : contentId + 'root_player'}
        className='ReactPlayer-container'
      >
        <div style={{ position: 'relative' }}>
          {isBeforeStartTime && (
            <div
              className='ReactPlayer-schedule-message'
              style={{
                backgroundImage: `url(${posterUrlObj?.poster?.src})`
              }}
            ></div>
          )}
          <ReactPlayerLoader
            key={trailerId ? trailerId : contentId + 'player'}
            accountId={config.accountId}
            playerId={config.playerId}
            videoId={trailerId ? trailerId : contentId}
            attrs={{
              className: 'ReactPlayer vjs-playlist-player-container vjs-fluid',
              id: 'content-detailed-player'
            }}
            options={{
              autoplay: 'any'
            }}
            onFailure={(e) => {
              throw e
            }}
            onSuccess={onSuccessPlayer}
          />
        </div>
      </Grid>
      {userData?.subscription?.subscription_status?.toLowerCase() !== 'active' && (
        <Grid>
          <div
            className={triggerEvent === 'ad' ? 'companion_ad_div' : 'hidden'}
            id={`companionDiv`}
          ></div>
          <div className={triggerEvent !== 'ad' ? 'bannerDiv' : 'hidden'} id={`bannerDiv`}></div>
        </Grid>
      )}
      <DialogSubscribe
        isDialogOpen={isSubscribeDialogOpen}
        message='dialog.sub_title_success'
        onDialogClose={handleSubscribeDialogClose}
        onSubscribeNow={handleSubscribeDialogToSubscribe}
      />
    </>
  )
}
export default ReactPlayer
