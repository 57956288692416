import { useTranslation } from 'react-i18next'

import { UserPreferencesObject } from '../types/userProfile'
import { ContentSection } from '../types/section'
import { VideoContentItem } from '../types/item'

import { getStorage } from './localStorageSupport'

const storage = getStorage()

let seriesTrailerId: any = ''
let seasonTrailerId: any = ''

export const hasOnlyNumbers = (str: string): boolean => /^\d+$/.test(str)

export const formatDuration = (durationInMillis: number): string => {
  let duration = ''
  const seconds = Math.floor((durationInMillis / 1000) % 60)
  const minutes = Math.floor((durationInMillis / (1000 * 60)) % 60)
  const hours = Math.floor((durationInMillis / (1000 * 60 * 60)) % 24)
  if (hours >= 0) {
    duration += `${hours < 10 && hours > 0 ? hours : hours}:`
  }

  if (minutes >= 0) {
    duration += `${minutes < 10 ? '0' + minutes : minutes}:`
  }

  if (seconds >= 0) {
    duration += `${seconds < 10 ? '0' + seconds : seconds} min`
  }

  return duration
}

export const getTimeStampFromISOString = (isoDateString: string): number =>
  Date.parse(isoDateString)

export const isFullScreen = (): boolean => document.fullscreenElement !== null

export const exitFullScreen = (): void => {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  }
}

export const isEmptyObject = (input: Record<string, any> | undefined): boolean =>
  !input || (Object.keys(input)?.length === 0 && input?.constructor === Object)

export const getApiHeader: any = (headers: any, _, isAuthRequired = false) => {
  const authToken = storage?.getItem('token')
  // const authToken = state.getState().config.token
  headers.set('Content-type', 'application/json')
  headers.set('platform', 'web')
  if (isAuthRequired) {
    if (authToken) {
      headers.set('Authorization', `Bearer ${authToken}`)
    }
  }
  return headers
}

export const translate = (label: string): string => {
  const { t } = useTranslation()
  return t(label)
}

export const getPageSize = (): number => 50

export const termsLink = (language: string): string =>
  `https://assets.oned.net/doc/term-condition-${language}.html`
export const privacyLink = (language: string): string =>
  `https://assets.oned.net/doc/privacy-policy-${language}.html`
export const cookiePolicyLink = (language: string): string =>
  `https://assets.oned.net/doc/cookie-policy-${language}.html`

export const userCookie: UserPreferencesObject = {
  cookies: {
    performace: true,
    functionality: true,
    targeting: true,
    advertising: true
  }
}

export const clearPrevTrailerIds = (): void => {
  seriesTrailerId = ''
  seasonTrailerId = ''
}

export const isVideoItem = (
  itemToCheck: VideoContentItem | ContentSection
): itemToCheck is VideoContentItem => (itemToCheck as VideoContentItem).video_id !== undefined

export const getItemDetailScreen = (
  itemToCheck: VideoContentItem | ContentSection
): VideoContentItem | null => {
  if (isVideoItem(itemToCheck)) {
    const videoItem = Object.assign({}, itemToCheck, {
      seriesTrailerId: seriesTrailerId,
      seasonTrailerId: seasonTrailerId
    })
    return videoItem
  } else {
    if (itemToCheck.items && itemToCheck.items.length > 0) {
      if (itemToCheck['collection_type'] === 'Series') {
        seriesTrailerId = itemToCheck?.trailerId
      } else if (itemToCheck['collection_type'] === 'Season') {
        if (seriesTrailerId === '') {
          seasonTrailerId = itemToCheck?.trailerId
        }
      }
      return getItemDetailScreen(itemToCheck?.items[0])
    } else {
      return null
    }
  }
}

export const objectToQueryString = (obj: object): string => {
  const keys = Object.keys(obj)
  const keyValuePairs = keys.map(
    (key) => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
  )
  return keyValuePairs.join('&')
}

export const getDataFromUrl = (data: string): object => {
  const paramData = decodeURIComponent(atob(data)).split('&')
  const objParam = {}
  for (let i = 0; i < paramData.length; i = i + 1) {
    const reqData = paramData[i].split('=')
    objParam[reqData[0]] = reqData[1]
  }
  return objParam
}

const userAgent = navigator.userAgent.toLowerCase()
export const isRealTablet =
  /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent
  )

export const hideToActivationPage = (blockedUrls: string[], pathname: string): any => {
  if (blockedUrls.some((blockedUrl) => pathname.startsWith(blockedUrl))) return true
}

export const gaCharsLimitation = (attr: string | undefined, limit: number): any => {
  if (!attr) return undefined
  const cleanedAttr = attr.split(',')
  const result = cleanedAttr.slice(0, limit)
  return result.join(', ')
}

// export const getPalSDKParams = (userData?: UserProfile | null): any => {
//   let ppid: string | undefined

//   if (userData?.id) {
//     const hash = CryptoJS.MD5(userData.id)
//     ppid = hash.toString(CryptoJS.enc.Hex)
//   }

//   return {
//     sessionId: 'bcSession',
//     ppid: ppid
//   }
// }
