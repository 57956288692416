/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useBeforeUnload } from 'react-router-dom'
import { useNavigate } from 'react-router'
import TagManager from 'react-gtm-module'
import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import './LiveTV.scss'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

//import TabPanel from '../../components/LiveTv/TabPanel'
import { Flex } from '@brightcove/studio-components'
import ReactPlayerLoader from '@brightcove/react-player-loader'
import Loading from '../../components/Loading/Loading'
import DialogSubscribe from '../../components/Common/DialogSubscribe/DialogSubscribe'

const { REACT_APP_VIDEO_LIVE_POLICY_KEY } = process.env

import { isFullScreen, exitFullScreen } from '../../utils/utils'
import { useLazyGetChannelDetailsQuery } from '../../store/services/livetv/livetvApi'
import config from '../../config'
import Description from '../../components/LiveTv/Description'
import { useLazyGetDynamicStringsQuery } from '../../store/services/dynamicStrings/dynamicStringsApi'
import { useLazyCheckEntitlementApiQuery } from '../../store/services/entitlement/entitlementApi'
import { updateEntitlement, updateSuccessEntitlement } from '../../store/slices/entitlementSlice'
import { googlePublishCompanionAds } from '../../hooks/useGooglePublisherTag'
import { userSelector } from '../../store/slices/config/configSelectors'
import { useDispatch, useSelector } from 'react-redux'
import { NEILSON_APP_ID, entitlementErrorCodes, entitlementPayload } from '../../constants'
import { RootState } from '../../store'
import { EntitlementStyles } from '../../components/Sections/Sections.style'
import { getStorage } from '../../utils/localStorageSupport'

const TabPanel = (props) => {
  const { children, value, index, data, ...other } = props

  const nielsonData = useRef({})
  nielsonData.current = { ...data.nielson }

  const [getAdSlotId]: any = useLazyGetDynamicStringsQuery()
  const [isPlaying, setIsPlaying] = useState(false)
  const [checkEntitlementApi] = useLazyCheckEntitlementApiQuery()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [availableAfterValue, setAvailableAfterValue] = useState(null)
  const { successEntitlement } = useSelector((state: RootState) => state.entitlement)
  const { isLogged, data: userData } = useSelector((state: RootState) => state.userProfile)
  const [isSubscribeDialogOpen, setIsSubscribeDialogOpen] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const playerRef = useRef() as MutableRefObject<ReactPlayerLoader>
  const storage = getStorage()

  const uid = storage?.getItem('uid')
  const { isNonValidEntitlement } = useSelector((state: RootState) => state.entitlement)
  const [isAdsDisable, setIsAdsDisable] = useState(false)

  useEffect(() => {
    if (playerRef?.current) {
      nSdkInstance?.ggPM('end', getPlayHeadPosition())
      nSdkInstance?.ggPM('stop', getPlayHeadPosition())
    }
  }, [index])

  // code will return the play head position
  const getPlayHeadPosition = () => Math.floor(new Date().getTime() / 1000)

  // Get the content metadata for the analytics
  const getContentMetaData = () => ({
    type: nielsonData.current['type'] || '',
    assetid: nielsonData.current['nielsenId'] || '',
    program: nielsonData.current['program'] || '',
    title: nielsonData.current['title'] || '',
    length: nielsonData.current['length'] || '',
    segB: nielsonData.current['segB'] || '',
    segC: nielsonData.current['segC'] || '',
    isfullepisode: nielsonData.current['isfullepisode'] || '',
    vcid: nielsonData.current['vcid'] || ''
  })

  let nSdkInstance: any = null
  // let contentMetadataObject: any = null
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false)

  // get the ad slot id from the backend
  const getAdSlotIdFromMW = async () => {
    const response = await getAdSlotId({
      q: 'web_companion_ad_slot_id'
    })
    return response
  }

  // rendering the companion ads
  useEffect(() => {
    const adSlotId = async () => {
      const response = await getAdSlotIdFromMW()
      const adId = document.getElementById(`companionDiv-${data?.asset_id}`)
      if (adId != null) {
        if (response && response.data && response.data.results && response.data.results.items) {
          if (response.data.results.items.length > 0) {
            googlePublishCompanionAds(response.data.results.items[0].value, adId.id)
          }
        }
      }
    }
    if (playerRef && playerRef?.current) adSlotId()
  }, [isPlaying])

  // Request entitlement api if the user is logged in
  useEffect(() => {
    if (isLogged) {
      checkEntitlement()
    }
  }, [isLogged, index])

  // Entitlement api request payload
  const payload = entitlementPayload(uid as string, data?.asset_id as string)

  // Get the entitlement data and update the global state
  const checkEntitlement = async () => {
    try {
      const response: any = await checkEntitlementApi(payload)
      setIsPageLoading(false)
      if (response.status === 'rejected') {
        const errorCode = response?.error?.data?.code
        dispatch(updateEntitlement(true))
        if (entitlementErrorCodes.includes(errorCode)) {
          if (response?.error?.data?.message?.includes('availableAfter')) {
            const pattern = /availableAfter: (\d+)/
            setAvailableAfterValue(response?.error?.data?.message.match(pattern)[1])
          }
          setIsSubscribeDialogOpen(true)
        }
      } else if (response.status === 'fulfilled') {
        dispatch(updateEntitlement(false))
        dispatch(updateSuccessEntitlement(response.data))
      }
    } catch (error: any) {
      if (entitlementErrorCodes.includes(error.code)) {
        dispatch(updateSuccessEntitlement(true))
      }
    }
  }

  // request entitlement api once after getting the assetId
  useEffect(() => {
    checkEntitlement()
  }, [data?.asset_id])

  // Initializing the Nielson SDK
  nSdkInstance = window.NOLBUNDLE.nlsQ(NEILSON_APP_ID, '<ONE31-SAMPLE>', {
    nol_sdkDebug: 'debug'
  })

  let intervalId: any = ''
  // called when the video playing status changed
  useEffect(() => {
    // start the interval when video playing status is true
    if (isVideoPlaying) {
      intervalId = setInterval(() => {
        nSdkInstance?.ggPM('setPlayheadPosition', getPlayHeadPosition())
      }, 1000)
    }
    return () => {
      clearInterval(intervalId)
    }
  }, [isVideoPlaying])

  // handle the close subscription dialog
  const handleSubscribeDialogClose = () => {
    setIsSubscribeDialogOpen(false)
  }

  // handle the subscription dialog and navigate to subscribe page
  const handleSubscribeDialogToSubscribe = () => {
    setIsSubscribeDialogOpen(false)
    navigate('/oned/premium')
  }

  // called when before leaving the page, and stop (refresh the page)
  useBeforeUnload(
    React.useCallback(() => {
      nSdkInstance?.ggPM('end', getPlayHeadPosition())
      nSdkInstance?.ggPM('stop', getPlayHeadPosition())
    }, [])
  )

  // called when unmount the component (When navigate to different page)
  useEffect(
    () => () => {
      nSdkInstance?.ggPM('end', getPlayHeadPosition())
      nSdkInstance?.ggPM('stop', getPlayHeadPosition())
    },
    []
  )

  useEffect(() => {
    if (uid) {
      checkEntitlementApi(payload).then((entitlement: any) => {
        if (entitlement?.data?.features?.isAds === false) {
          setIsAdsDisable(true)
        } else {
          setIsAdsDisable(false)
        }
      })
    } else {
      setIsAdsDisable(false)
    }
  }, [uid])

  // called when player initialized success
  const onSuccessPlayer = (success) => {
    const mPlayer = success.ref

    mPlayer.ssai().googlePal.setStorageConsent(true)
    // mPlayer.ssai().googlePal.setParams(getPalSDKParams(userData))

    // disabling the ads if the feature not true
    mPlayer.on('ima3-ads-manager-loaded', () => {
      if (isAdsDisable) {
        mPlayer.ima3.adsManager.destroy()
      }
    })
    // Send the content meta data when load started
    mPlayer.on('loadstart', function () {
      nSdkInstance?.ggPM('loadMetadata', getContentMetaData())
    })

    // registering the player ready event
    mPlayer.ready(function () {
      playerRef.current = mPlayer
      mPlayer
        .getChild('controlBar')
        .el()
        .insertBefore(
          mPlayer.addChild('pictureInPictureToggle', {}).el(),
          document.getElementsByClassName('vjs-fullscreen-control')[0]
        )
      const catalogParams = {}
      catalogParams['type'] = 'video'
      catalogParams['id'] = data?.asset_id
      catalogParams['adConfigId'] = data?.ad_config_id
      catalogParams['policyKey'] = REACT_APP_VIDEO_LIVE_POLICY_KEY as string

      mPlayer.catalog.getVideo(catalogParams, function (error, video) {
        if (error) {
          throw error
        }
        mPlayer.catalog.load(video)
        mPlayer.on('loadedmetadata', function () {
          const closeButton: any = document.querySelector('.vjs-errors-ok-button')
          closeButton?.click()

          mPlayer.muted(true)
          mPlayer.play()
          setIsPlaying(true)
          setIsVideoPlaying(true)

          //start
          // Select all the quality menu items
          const qualityMenuHtmlItems = document.querySelectorAll(
            '.vjs-control-bar .vjs-quality-menu-wrapper .vjs-menu-content .vjs-menu-item'
          )
          const allMenuItem = playerRef.current.controlBar.getChild('QualityMenuButton').items

          const indexesWithHDSubLabel: number[] = []
          let autoIndex
          const maxResolutionLabel = successEntitlement?.features?.maxResolutionSupported || 720
          const preventResolution: number[] | any = []
          allMenuItem.forEach((item, i) => {
            const resolutionLabelWithoutP = item.options_.label.replace('p', '')
            if (parseInt(resolutionLabelWithoutP) > maxResolutionLabel) {
              indexesWithHDSubLabel.push(item.options_.levels[0])
              preventResolution.push(item.options_.label)
            }

            if (item.options_.controlText === 'Auto') autoIndex = i
          })

          playerRef.current.controlBar.getChild('QualityMenuButton').items[autoIndex].levels_ =
            allMenuItem[autoIndex].levels_.filter((item) => !indexesWithHDSubLabel.includes(item))

          playerRef.current.controlBar.getChild('QualityMenuButton').items[
            autoIndex
          ].options_.levels = allMenuItem[autoIndex].options_.levels.filter(
            (item) => !indexesWithHDSubLabel.includes(item)
          )

          // Iterate over each quality menu item
          qualityMenuHtmlItems.forEach((item) => {
            if (
              preventResolution?.includes(item?.querySelector('.vjs-menu-item-text')?.textContent)
            ) {
              item.addEventListener('click', () => {
                if (isFullScreen()) {
                  exitFullScreen()
                }
                let isSelected
                allMenuItem.forEach((item, i) => {
                  if (item.isSelected_) isSelected = i
                })
                playerRef.current.controlBar
                  .getChild('QualityMenuButton')
                  .items[isSelected].handleClick_()
                setIsSubscribeDialogOpen(true)
              })
            }
            if (item?.textContent?.includes('Auto')) {
              item.addEventListener('click', () => {
                playerRef.current.controlBar.getChild('QualityMenuButton').items[
                  autoIndex
                ].levels_ = allMenuItem[autoIndex].levels_.filter(
                  (item) => !indexesWithHDSubLabel.includes(item)
                )

                playerRef.current.controlBar.getChild('QualityMenuButton').items[
                  autoIndex
                ].options_.levels = allMenuItem[autoIndex].options_.levels.filter(
                  (item) => !indexesWithHDSubLabel.includes(item)
                )
              })
            }
          })
          //end
        })
      })

      // registering the play event
      mPlayer.on('play', () => {
        setIsPlaying(true)
        setIsVideoPlaying(true)
        if (mPlayer.paused()) {
          setIsVideoPlaying(false)
        }
      })

      // registering the playing event
      mPlayer.on('playing', () => {
        setIsVideoPlaying(true)
      })

      // registering the pause event
      mPlayer.on('pause', () => {
        setIsPlaying(false)
        setIsVideoPlaying(false)
      })

      // registering the content end event
      mPlayer.on('end', () => {
        setIsVideoPlaying(false)
        nSdkInstance?.ggPM('stop', getPlayHeadPosition())
        nSdkInstance?.ggPM('end', getPlayHeadPosition())
      })

      // registering the ad started event
      mPlayer.on('ads-ad-started', () => {
        // send the ad metadata to the analytics
        nSdkInstance?.ggPM('loadMetadata', {
          type: mPlayer.ads.adType || '',
          assetId: mPlayer.ads.ad.id || ''
        })
        setIsVideoPlaying(true)
        setIsPlaying(true)
      })

      // registering the ad pause event
      mPlayer.on('ads-pause', () => {
        setIsVideoPlaying(false)
        setIsPlaying(true)
      })

      mPlayer.on('ads-play', () => {
        setIsVideoPlaying(true)
      })

      // registering the ad ended event
      mPlayer.on('ads-ad-ended', () => {
        setIsVideoPlaying(false)
      })

      // registering the ad ended event
      mPlayer.on('adend', () => {
        setIsPlaying(false)
        setIsVideoPlaying(false)
        nSdkInstance?.ggPM('stop', getPlayHeadPosition())
        // send the content metadata to Nielson when ad ended
        nSdkInstance?.ggPM('loadMetadata', getContentMetaData())
      })

      //>>====SUBSCRIBE POPUP IF USER IS NOT SUBSCRIBED--------->
      // registering the enter pip event
      mPlayer.on('enterpictureinpicture', (e) => {
        if (!successEntitlement?.features?.isPIPEnabled) {
          if (isFullScreen()) {
            exitFullScreen()
          }
          e.preventDefault()
          document.exitPictureInPicture()
          setIsSubscribeDialogOpen(true)
        }
      })

      const controlBar = mPlayer.controlBar

      // Get the Chromecast button component
      const chromeCastButton = controlBar.getChild('ChromecastButton')
      // event listener to the Chromecast button
      chromeCastButton?.on('click', (event) => {
        if (!successEntitlement?.features?.isCastingEnabled) {
          if (isFullScreen()) {
            exitFullScreen()
          }
          event.preventDefault()
          event.stopImmediatePropagation()
          setIsSubscribeDialogOpen(true)
        }
      })
    })
  }

  return isPageLoading ? (
    <Loading />
  ) : (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className='tabPanel'
    >
      {/* {value === index && ( */}
      <>
        <Flex flexDirection='column'>
          <div className='ReactPlayer-container'>
            {isNonValidEntitlement ? (
              <EntitlementStyles />
            ) : (
              <ReactPlayerLoader
                attrs={{
                  className: 'ReactPlayer'
                }}
                accountId={config.accountId}
                playerId={
                  /**
                   * Delaying player initialization until user type check (premium/non-premium) to avoid parallel processing issues of JS engine.
                   */
                  !isAdsDisable ? config.livePlayerId : isAdsDisable && config.livePlayerId
                }
                videoId={data?.asset_id !== undefined ? data?.asset_id : ''}
                adConfigId={data?.ad_config_id !== undefined ? data?.ad_config_id : ''}
                onFailure={(e) => {
                  throw e
                }}
                onSuccess={onSuccessPlayer}
                options={{
                  autoplay: 'any'
                }}
              />
            )}
            {userData?.subscription?.subscription_status?.toLowerCase() !== 'active' && (
              <div className='companion_ad_div' id={`companionDiv-${data?.asset_id}`}></div>
            )}
          </div>
          <Description channelData={data} />
        </Flex>
        <DialogSubscribe
          isDialogOpen={isSubscribeDialogOpen}
          message={availableAfterValue ? 'dialog.sub_title_error' : 'dialog.sub_title_success'}
          availableAfter={availableAfterValue}
          onDialogClose={handleSubscribeDialogClose}
          onSubscribeNow={handleSubscribeDialogToSubscribe}
        />
      </>
      {/* )} */}
    </div>
  )
}

// live tv component
const LiveTV: FC = () => {
  const [value, setValue] = React.useState('')
  const [channelDetails, setChannelDetails] = useState([])
  const [selectedData, setSelectedData] = useState<any>()
  const handleChange = (_event, newValue) => {
    setValue(newValue?.id)
    setSelectedData(newValue)
  }
  const [LiveTV]: any = useLazyGetChannelDetailsQuery()
  const user = useSelector(userSelector)

  useEffect(() => {
    document.title = selectedData ? selectedData?.['name'] : 'oneD'
  }, [selectedData])

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'video_view',
        page_name: 'live_player',
        page_location: location?.href,
        video_id: selectedData?.['id'],
        video_title: selectedData?.['name'],
        user_id: user?.uid
      }
    }
    TagManager.dataLayer(tagManagerArgs)
  }, [selectedData])

  useEffect(() => {
    const getChannel = async () => {
      const items = await LiveTV()
      setChannelDetails(items?.data)
      setValue(items?.data?.[0]?.id)
      setSelectedData(items?.data?.[0])
      const tagManagerArgs = {
        dataLayer: {
          event: 'page_view',
          page_name: 'live_player',
          page_location: location?.href,
          channel_name: items?.data?.[0]?.name,
          user_id: user?.uid
        }
      }
      TagManager.dataLayer(tagManagerArgs)
    }
    getChannel()
  }, [])

  return (
    <>
      <div className='live-tv'>
        <Box className='liveTv-container'>
          <Tabs
            value={selectedData}
            variant='scrollable'
            onChange={handleChange}
            sx={{ color: '#F20B7E', background: '#222' }}
            aria-label='scrollable auto tabs example'
            scrollButtons='auto'
          >
            {channelDetails?.map((channel: any) => (
              <Tab
                key={channel.id}
                value={channel}
                className='tabsHeader'
                sx={{ background: '#222', minWidth: '50%' }}
                icon={<img src={channel.image} height={'98px'} width={'194px'}></img>}
              />
            ))}
          </Tabs>
          {channelDetails?.length && <TabPanel value={value} index={value} data={selectedData} />}
        </Box>
      </div>
    </>
  )
}

export default LiveTV
