/* eslint-disable import/order */
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { FC, useEffect, useState } from 'react'
import { getAuth } from 'firebase/auth'

import SignUp from '../SignUp/SignUp'
import ProfilePage from '../Profile/ProfilePage'
import PrivacyDialog from '../PrivacySettings/PrivacyDialog'
import Maintenance from '../Maintenance/Maintenance'
import Login from '../Login/Login'
import Header from '../Header/Header'
import ForgotPassword from '../ForgotPassword/ForgotPassword'
import Footer from '../Footer/Footer'
import { updateNotificationLength } from '../../store/slices/notificationSlice'
import { setSiteLanguage, setToken } from '../../store/slices/config/configSlice'
import {
  languageSelector,
  maintenanceMessageSelector,
  userSelector
} from '../../store/slices/config/configSelectors'
import Search from '../../routes/Search/Search'
import MyList from '../../routes/MyList/MyList'
import HomePage from '../../routes/HomePage/HomePage'
import ContinueWatching from '../../routes/ContinueWatching/ContinueWatching'
import ContentDetailsPage from '../../routes/ContentDetailsPage/ContentDetailsPage'
import Content from '../../routes/Content/Content'
import useAuth from '../../hooks/useAuth'
import './AppBody.scss'
import { useLocalStorage } from '../../hooks/UIHooks'
import LiveTV from '../../routes/LiveTV/LiveTV'
import Faq from '../Faq/Faq'
import { useUpdateUserCookieMutation } from '../../store/services/user/userCookieApi'
import { userCookieSuccess } from '../../store/slices/userCookieSlice'
import {
  setUserProfileData,
  setUserProfileLoading,
  setUserStatus
} from '../../store/slices/userProfileSlice'
import { useLazyGetUserProfileQuery } from '../../store/services/user/userProfileApi'
import { useLazyGetMenuApiQuery } from '../../store/services/menu/menuApi'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  footerMenu,
  headerMenu,
  headerSubMenu,
  menuFailure,
  menuStart,
  menuSuccess
} from '../../store/slices/menuSlice'
import { getNotificationToken, onMessageListener } from '../../firebase'
import { RootState } from '../../store'
import SmartBannerComponent from '../Common/SmartBanner/SmartBanner'
import { useBreakPoints } from '../../hooks/useBreakPoints'
import { isRealTablet as isTablet } from '../../utils/utils'
import SubscriptionSuccess from '../SubscrptionSuccess/SubscriptionSuccess'
import OneDOriginal from '../../routes/OneDOriginal/OneDOriginal'
import Pricing, { RedeemRoute } from '../Pricing/Pricing'
import TvActivation from '../AndroidTv/TvActivation'
import { getStorage, isLocalStorageSupported } from '../../utils/localStorageSupport'
import AISActivation from '../../routes/AIS/AISActivation'

const AppBody: FC = () => {
  const { i18n } = useTranslation()
  const i18nLang = i18n.resolvedLanguage?.toLowerCase()
  const dispatch = useDispatch()
  const maintenanceMessage = useSelector(maintenanceMessageSelector)
  const language = useSelector(languageSelector)
  const basePath = language === 'en' ? `/${language}` : ''
  const [cookiesUpdatedSession, setCookiesUpdatedSession] = useLocalStorage('cookies_updated', '')
  const [cookies_settings, setCookiesSettingsSession] = useLocalStorage('cookies_settings', '')
  const [notificationList, setNotificationList] = useLocalStorage('notf_list')
  const [show, setShow] = useState(true)
  const [notification, setNotification] = useState({ title: '', body: '', image: '' })
  const location = useLocation()
  const auth = getAuth()
  const [updateCookieDetails] = useUpdateUserCookieMutation()
  const [getProfileData] = useLazyGetUserProfileQuery()
  const [getSiteConfiguration]: any = useLazyGetMenuApiQuery()
  const { menuItems } = useSelector((state: RootState) => state.menu)
  const navigate = useNavigate()
  const { isMobile } = useBreakPoints()
  const { isSmartBannerClosed } = useSelector((state: RootState) => state.notification)
  const user = useSelector(userSelector)
  useAuth(true)
  const storage = getStorage()

  const getItemNavigationPath = (NAV_ITEMS, NAV_ITEMS_SUBMENU, NAV_ITEMS_FOOTER, lang) => {
    const navItemsArray: any[] = []
    const navItemsSubArray: any[] = []
    const navItemsFooterArray: any[] = []
    for (const item of NAV_ITEMS || NAV_ITEMS_FOOTER) {
      const itemDetails = {}
      switch (item['name'].toLowerCase().replace(/\s/g, '')) {
        case 'home':
          itemDetails['title'] = lang === 'en' ? item['name'] : item['name_th']
          itemDetails['path'] = '/'
          itemDetails['isParent'] = false
          if (NAV_ITEMS_FOOTER) {
            navItemsFooterArray.push(itemDetails)
            NAV_ITEMS_FOOTER = NAV_ITEMS_FOOTER.filter(function (item1) {
              return item1['name'] !== item['name']
            })
          }
          break
        case 'livetv':
          itemDetails['title'] = lang === 'en' ? item['name'] : item['name_th']
          itemDetails['path'] = '/live-tv'
          itemDetails['isParent'] = false
          if (NAV_ITEMS_FOOTER) {
            navItemsFooterArray.push(itemDetails)
            NAV_ITEMS_FOOTER = NAV_ITEMS_FOOTER.filter(function (item1) {
              return item1['name'] !== item['name']
            })
          }
          break
        case 'onedoriginal':
          itemDetails['title'] = lang === 'en' ? item['name'] : item['name_th']
          itemDetails['path'] = '/oned/original'
          itemDetails['isParent'] = false
          if (NAV_ITEMS_FOOTER) {
            navItemsFooterArray.push(itemDetails)
            NAV_ITEMS_FOOTER = NAV_ITEMS_FOOTER.filter(function (item1) {
              return item1['name'] !== item['name']
            })
          }
          break
        case 'onedpremium':
          itemDetails['title'] = lang === 'en' ? item['name'] : item['name_th']
          itemDetails['path'] = '/oned/premium'
          itemDetails['isParent'] = false
          if (NAV_ITEMS_FOOTER) {
            navItemsFooterArray.push(itemDetails)
            NAV_ITEMS_FOOTER = NAV_ITEMS_FOOTER.filter(function (item1) {
              return item1['name'] !== item['name']
            })
          }
          break
        case 'mylist':
          itemDetails['title'] = lang === 'en' ? item['name'] : item['name_th']
          itemDetails['path'] = '/my-list'
          itemDetails['isParent'] = false
          if (NAV_ITEMS_FOOTER) {
            navItemsFooterArray.push(itemDetails)
            NAV_ITEMS_FOOTER = NAV_ITEMS_FOOTER.filter(function (item1) {
              return item1['name'] !== item['name']
            })
          }
          break
        case 'more':
          itemDetails['title'] = lang === 'en' ? item['name'] : item['name_th']
          itemDetails['path'] = '/more'
          itemDetails['isParent'] = true
          break
      }
      if (NAV_ITEMS_FOOTER === null) {
        navItemsArray.push(itemDetails)
      }
    }
    for (const item of NAV_ITEMS_SUBMENU || NAV_ITEMS_FOOTER) {
      const itemDetails = {}
      itemDetails['title'] = lang === 'en' ? item['name'] : item['name_th']
      if (
        item['name'].toLowerCase().replace(/\s/g, '') === 'activities' ||
        item['name'].toLowerCase().replace(/\s/g, '') === 'faq'
      ) {
        itemDetails['path'] = `/${item['name']}`
      } else {
        itemDetails['path'] = `/content/${item['name']}`
      }
      itemDetails['isParent'] = false
      if (NAV_ITEMS_FOOTER === null) {
        navItemsSubArray.push(itemDetails)
      } else {
        navItemsFooterArray.push(itemDetails)
      }
    }
    if (NAV_ITEMS_FOOTER === null) {
      dispatch(headerMenu(navItemsArray))
      dispatch(headerSubMenu(navItemsSubArray))
    } else {
      dispatch(footerMenu(navItemsFooterArray))
    }
  }

  const getMenuItems = (items, lang) => {
    const NAV_ITEMS = items?.headerItems?.slice(0, 5) || []
    const homeIndex = NAV_ITEMS?.findIndex((item) => item.name === 'Home')

    if (homeIndex !== -1) {
      const homePosition = NAV_ITEMS.splice(homeIndex, 1)[0]
      NAV_ITEMS?.unshift(homePosition)
    }

    const NAV_ITEMS_SUBMENU = items?.headerItems?.slice(-(items?.headerItems?.length - 5)) || []
    const NAV_ITEMS_FOOTER = items?.footerItems
    if (NAV_ITEMS_SUBMENU && NAV_ITEMS_SUBMENU?.length > 0) {
      const NAV_MORE = {
        name: 'More',
        type: 'Grid',
        name_th: 'เพิ่มเติม',
        id: ''
      }
      NAV_ITEMS?.push(NAV_MORE)
    }
    if (NAV_ITEMS && NAV_ITEMS?.length > 0 && NAV_ITEMS_SUBMENU && NAV_ITEMS_SUBMENU?.length > 0) {
      getItemNavigationPath(NAV_ITEMS, NAV_ITEMS_SUBMENU, null, lang)
      getItemNavigationPath(null, null, NAV_ITEMS_FOOTER, lang)
    }
  }

  const getSiteConfigurationItems = async (lang) => {
    dispatch(menuStart())
    const siteConfig = await getSiteConfiguration()
    if (siteConfig.data) {
      getMenuItems(siteConfig?.data, lang)
      dispatch(menuSuccess(siteConfig?.data))
    } else {
      dispatch(menuFailure(siteConfig?.error?.data?.message))
    }
  }

  useEffect(() => {
    i18n.addResources(language, 'translation', {})

    if (i18nLang) {
      dispatch(setSiteLanguage(i18nLang))
    }
    getSiteConfigurationItems(i18nLang)
  }, [])

  useEffect(() => {
    if (menuItems) {
      getMenuItems(menuItems, i18nLang)
    }
  }, [i18nLang])

  useEffect(() => {
    if ('Notification' in window && Notification.permission === 'granted') {
      getNotificationToken()
    }
    onMessageListener()
      .then((payload) => {
        setShow(true)
        // eslint-disable-next-line no-console
        console.log('notification payload ', payload)
        setNotification({
          title: payload?.['notification']?.['title'] ?? '',
          body: payload?.['notification']?.['body'] ?? '',
          image: payload?.['notification']?.['image'] ?? ''
        })
        const notfArray: any[] = []
        if (notificationList) {
          JSON.parse(notificationList)
          notfArray.push(...JSON.parse(notificationList))
        }
        const notfObj = {
          title: payload?.['notification']?.['title'],
          body: payload?.['notification']?.['body'],
          image: payload?.['notification']?.['image'],
          videoId: payload?.['data']?.['videoId'],
          timeStamp: new Date().toLocaleDateString(),
          isRead: false
        }
        notfArray.push(notfObj)
        // eslint-disable-next-line no-console
        setNotificationList(JSON.stringify(notfArray))
        if (notfArray) {
          const filteredData = notfArray.filter((x) => x.isRead === false)
          dispatch(updateNotificationLength(filteredData.length))
        }
      })
      .catch(() => {
        storage?.removeItem('notf_list')
      })
  }, [])

  useEffect(() => {
    const onAuthChange = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        refreshAuthToken(authUser)
      }
    })

    return () => onAuthChange()
  }, [])

  const refreshAuthToken = async (authUser: any) => {
    try {
      const idToken = await authUser.getIdToken()
      dispatch(setToken(idToken))
      storage?.setItem('token', idToken)
      storage?.setItem('uid', authUser.uid)

      const expiresIn = authUser.stsTokenManager.expirationTime || 0
      const refreshTime = expiresIn - Date.now() - 5 * 60 * 1000
      if (refreshTime > 0) {
        setTimeout(async () => {
          await authUser.getIdToken(true)
          refreshAuthToken(authUser)
          // navigate(0) // refresh page
        }, refreshTime)
      }
    } catch (error) {
      // console.error('Error refreshing ID token:', error)
    }
  }

  // useEffect(() => unregister(), [])

  const updateUserCookiePreference = async () => {
    if (storage?.getItem('cookies_settings') !== null) {
      const userCookie = !isLocalStorageSupported()
        ? JSON.parse(localStorage?.getItem('cookies_settings') || '{}')
        : storage?.getItem('cookies_settings')
      await updateCookieDetails(userCookie)
      setCookiesUpdatedSession(true)
      setCookiesSettingsSession(JSON.stringify(userCookie))
    }
  }

  const token = storage?.getItem('token')
  useEffect(() => {
    window.scrollTo(0, 0)
    if (token && !cookiesUpdatedSession) {
      updateUserCookiePreference()
      dispatch(setUserProfileLoading(true))
    } else {
      dispatch(setUserProfileLoading(false))
    }
  }, [location, token])

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user && token) {
        dispatch(setUserProfileLoading(true))
        getProfileData().then((profileResponse) => {
          dispatch(setUserProfileData(profileResponse?.['data']))
          dispatch(userCookieSuccess())
          dispatch(setUserProfileLoading(false))
        })
      } else {
        dispatch(setUserStatus('N'))
        dispatch(setUserProfileLoading(false))
      }
    })
  }, [auth, token])

  const displayMaintenance = maintenanceMessage != null

  const notify = () => {
    toast(
      <div className='notification'>
        <div>{notification?.title}</div>
        <div>{notification?.body}</div>
      </div>,
      {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
        onClick: () => {
          const token = storage?.getItem('token')
          if (token) {
            navigate('/profile')
          } else {
            navigate('/login')
          }
        },
        toastId: new Date().getMilliseconds() + ''
      }
    )
    setShow(false)
  }

  useEffect(() => {
    const restrictedPaths = ['/login', '/password-reset', '/signup']
    const allowedPaths = ['/my-list', '/profile']
    if (allowedPaths.includes(location.pathname) && !user) {
      navigate('/login')
      return
    }
    if (restrictedPaths.includes(location.pathname)) {
      const shouldNavigateBack = !isLocalStorageSupported()
        ? sessionStorage?.getItem('shouldNavigateBack')
        : storage.getItem('shouldNavigateBack')

      if (shouldNavigateBack === 'true') {
        if (allowedPaths.includes(location.pathname)) navigate('/login')
        navigate(-1)
      }
    }
  }, [location.pathname])

  const isTabletView =
    (!isSmartBannerClosed && location.pathname.includes('search')) ||
    location.pathname.startsWith('/continue') ||
    location.pathname.startsWith('/profile')

  return (
    <>
      {(isMobile || isTablet) && !isSmartBannerClosed && <SmartBannerComponent />}
      <div
        className='app-layout'
        style={{
          transform: `${
            isSmartBannerClosed
              ? 'none'
              : (!isMobile && !isTablet) || location.pathname.startsWith('/profile')
                ? 'none'
                : 'translateY(84px)'
          }`
        }}
      >
        {displayMaintenance && (
          <>
            <Header
              isTabletView={isTabletView && isTablet}
              isSmartBannerClosed={isSmartBannerClosed}
            />
            <div className='app-body'>
              <Maintenance />
            </div>
            <Footer />
          </>
        )}
        {!displayMaintenance && show && (
          <>
            {notify()}
            <ToastContainer limit={1} hideProgressBar={true} />
          </>
        )}
        {!displayMaintenance && (
          <>
            <Header
              isTabletView={isTabletView && isTablet}
              isSmartBannerClosed={isSmartBannerClosed}
            />
            <div className='app-body'>
              <Routes>
                <Route path='/login' element={<Login />} />
                <Route path={`${basePath}/`} element={<HomePage />} />
                <Route path={`/`} element={<HomePage />} />
                <Route path={`${basePath}/password-reset`} element={<ForgotPassword />} />
                <Route path={`/password-reset`} element={<ForgotPassword />} />
                <Route path={`/signup/*`} element={<SignUp />} />
                <Route path={`${basePath}/profile/*`} element={<ProfilePage />} />
                <Route path={`/profile/*`} element={<ProfilePage />} />
                <Route path={`/video/:id`} element={<ContentDetailsPage />} />
                <Route path='*' element={<HomePage />} />
                <Route path='/search' element={<Search />} />
                <Route path='/my-list' element={<MyList />} />
                <Route path='/continue-watching' element={<ContinueWatching />} />
                <Route path={`/content/:path`} element={<Content />} />
                <Route path='/live-tv' element={<LiveTV />} />
                <Route path={`/faq`} element={<Faq dynamic_string_key={'faq_url'} />} />
                <Route path={`/oned/premium/:path?`} element={<Pricing />} />
                <Route path='/oned/premium/redeem' element={<RedeemRoute />} />
                <Route
                  path={`/Activities`}
                  element={<Faq dynamic_string_key={'activities_url'} />}
                />
                <Route path={`/subscription`} element={<SubscriptionSuccess />} />
                <Route path='/oned/original' element={<OneDOriginal />} />
                <Route path={`/activation/*`} element={<TvActivation />} />
                <Route path={`/ais/activate`} element={<AISActivation />} />
              </Routes>
            </div>
            <Footer />

            {!cookies_settings && (
              <div className='privacy_holder'>
                <PrivacyDialog
                  onUpdateDialog={() => {
                    document
                      .getElementsByClassName('privacy_holder')[0]
                      ?.classList.add('hide_privacy')
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default AppBody
